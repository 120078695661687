import React, { useEffect, useRef } from 'react';
import { useCart } from 'apps/commerce/cart';
import { useTranslation } from 'react-i18next';
import { Loader, Divider, SageClassnames, Icon } from '@kajabi/sage-react';
import './styles/index.scss';
import CheckoutCart from 'apps/commerce/cart/CheckoutCart';
import ManageCart from 'apps/commerce/cart/ManageCart';
import CartMessage from 'apps/commerce/cart/CartMessage';

const Cart = () => {
  const {
    context: { cart, step },
    hideModal,
    modal,
    updateCartItem,
    removeItemFromCart,
    goBackToCart,
    goToCheckout,
    submitCheckout,
    warningCode,
  } = useCart();
  const { t } = useTranslation();

  const modalRef = useRef<HTMLDivElement>(null);
  const modalClosed = modal.component === null;

  useEffect(() => {
    if (!modalClosed && modalRef.current) {
      modalRef.current.focus();
    }
  }, [modal.component, modalClosed]);

  const getFooter = React.useMemo(
    () => () => {
      const footerHtml = document.querySelector('.footer');
      return (
        <div className="cart-footer">
          <div
            className="footer-content"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: footerHtml?.innerHTML || '' }}
          />
        </div>
      );
    },
    [],
  );

  const cartItems = cart?.items || [];
  const hasItems = !!cartItems.length;

  return (
    <section className="cart-body" ref={modalRef} tabIndex={-1} aria-labelledby="cart-title">
      {step === 1 && <Divider />}
      <div className="cart-content">
        {step === 0 && (
          <>
            {cart === undefined && <Loader className="cart-loader" loading={true} />}
            {cart && (
              <h5
                id="cart-title"
                className={`cart-title ${SageClassnames.TYPE_ALIGN_CENTER} ${SageClassnames.SPACERS.XL_BOTTOM}`}
              >
                {t('messages.cart.shopping_cart')}
              </h5>
            )}
            {warningCode && (
              <CartMessage
                code={warningCode}
                type="warning"
                padding="sm"
                className={SageClassnames.SPACERS.LG_BOTTOM}
              />
            )}
            {cart && hasItems && (
              <ManageCart
                cart={cart}
                updateCartItem={updateCartItem}
                removeItemFromCart={removeItemFromCart}
                goToCheckout={goToCheckout}
              />
            )}
            {cart && !hasItems && (
              <div className="sage-empty-state">
                <Icon icon={Icon.ICONS.CART} size={Icon.SIZES.XXXXL} color={Icon.COLORS.GREY_400} />
                <p className={`${SageClassnames.TYPE.BODY} ${SageClassnames.TYPE_COLORS.GREY_800}`}>
                  {t('messages.cart.empty_cart')}
                </p>
              </div>
            )}
          </>
        )}
        {step === 1 && cart && (
          <CheckoutCart
            cart={cart}
            goBackToCart={goBackToCart}
            submitCheckout={submitCheckout}
            hideModal={hideModal}
          />
        )}
      </div>
      {step === 0 && getFooter()}
    </section>
  );
};

export default Cart;

import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Alert, Frame } from '@kajabi/sage-react';

interface CartMessageInterface {
  code: string;
  type: string;
  padding?: string;
  className?: string;
}

export default function CartMessage({
  code,
  type,
  className,
  padding = 'none',
}: CartMessageInterface) {
  const { t } = useTranslation();
  const [show, setShow] = useState(true);

  function hideElement() {
    setShow(false);
  }

  return (
    <>
      {show && (
        <Frame
          align={Frame.ALIGNMENTS.CENTER_CENTER}
          direction={Frame.DIRECTIONS.HORIZONTAL}
          padding={padding}
          className={className}
        >
          <Alert
            description={t(`messages.cart.${code}`)}
            color={type}
            dismissable
            onDismiss={hideElement}
          />
        </Frame>
      )}
    </>
  );
}

import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Icon } from '@kajabi/sage-react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { loadStripe, Stripe } from '@stripe/stripe-js';

import { Cart, Context, OrderCheckout } from 'apps/commerce/cart/types';
import CartContext, { CartContextObject, ModalProps } from './CartContext';
import cartClient from './utils/cartClient';
import CheckoutSettings from '../common/checkout/types/CheckoutSettings';
import { disableButton, enableButton, disableScroll, enableScroll } from './utils';
import useToast from '../common/checkout/hooks/useToast';
import { verifyDomainForApplePay } from '../common/utils';
import Site from '../common/checkout/types/Site';

interface Window {
  ApplePaySession?: {};
}

const CartProvider: React.FC = ({ children }) => {
  const [cart, updateCartState] = useState<undefined | Cart>();
  const [context, setContext] = useState<Context>({ cart, step: 0 });
  const [modalState, setModalState] = useState<ModalProps>({
    component: null,
    props: {},
  });
  const [warningCode, setWarningCode] = useState<undefined | string>();
  // @ts-ignore -- todo: add type to useToast
  const { showToast } = useToast();
  const { t } = useTranslation();

  const { getValues, watch, setValue, handleSubmit, reset } = useFormContext();
  const checkoutSettings: CheckoutSettings | undefined = watch('checkoutSettings');
  const applePayDomainVerified: boolean | undefined = watch('applePayDomainVerified');

  const { totalPrice } = cart || {};
  // determine if we are in the theme editor
  const [isEditing] = useState(!!window.Kajabi?.theme?.editor);

  // memoized cart count
  const cartCount = useMemo(
    () => cart?.items.reduce((acc, item) => acc + item.quantity, 0),
    [cart?.items],
  );

  // if the total price of the cart is greater than 0, we can require payment
  useEffect(() => {
    if (totalPrice && totalPrice?.amount > 0) {
      setValue('requirePayment', true);
    } else if (totalPrice && totalPrice?.amount === 0) {
      setValue('requirePayment', false);
    }
  }, [totalPrice]);

  useEffect(() => {
    if (checkoutSettings) {
      if (checkoutSettings.stripePublishableKey && checkoutSettings.stripeAccountId) {
        loadStripe(checkoutSettings.stripePublishableKey, {
          betas: ['elements_enable_deferred_intent_beta_1'],
          stripeAccount: checkoutSettings.stripeAccountId,
        }).then((stripe: Stripe | null) => setValue('stripe', stripe));
      }
    }
  }, [checkoutSettings?.stripePublishableKey, checkoutSettings?.stripeAccountId]);

  // initialize cart and add to cart buttons on mount
  useEffect(() => {
    // fetch cart on mount
    fetchCart();

    // initialize add to cart buttons in the DOM
    const addToCartButtons = document.querySelectorAll('a[href^="#cart_item"]');

    // if there are no add to cart buttons or we are in the theme editor, return
    if (!addToCartButtons.length || isEditing) return;

    addToCartButtons.forEach(async (button) => {
      button.addEventListener('click', async (e: { preventDefault: () => void; target: any }) => {
        e.preventDefault();
        const itemId: string = button.getAttribute('href')?.split('#cart_item_')[1] || '';
        await handleAddToCart(itemId, button as HTMLAnchorElement);
      });
    });
  }, []);

  // reset cart when modal is opened
  useEffect(() => {
    if (modalState.component) resetCart();
  }, [modalState]);

  // update context when cart changes
  useEffect(() => {
    let { step } = context;
    if (cart && cart.items.length <= 0) {
      step = 0;
    }
    setContext({ ...context, cart, step });
  }, [cart]);

  useEffect(() => {
    if (!cart || !checkoutSettings) return;
    // skip if no ApplePay session exists
    if (!(window as Window).ApplePaySession) return;
    // skip if ApplePay is not enabled on the Offer
    if (!checkoutSettings?.additionalPaymentMethods?.includes('apple_pay')) return;
    // skip if the domain has already been verified
    if (applePayDomainVerified) return;
    verifyDomainForApplePay(cart.site as Site).then(async () => {
      setValue('applePayDomainVerified', true);
    });
  }, [cart?.site, checkoutSettings]);

  const resetCart = useCallback(() => {
    goBackToCart();
    updateCartState(undefined);
    reset();
    fetchCart();
  }, []);

  const handleAddToCart = useCallback(
    async (itemId: string, button: HTMLAnchorElement) => {
      disableButton(button as HTMLAnchorElement);
      await addItemToCart(itemId);
      // re-enable button after 1s to prevent double clicks
      setTimeout(() => {
        enableButton(button);
      }, 1000);
    },
    [cart],
  );

  const handleSetWarningCode = useCallback((code: string | undefined) => {
    setWarningCode(code);
  }, []);

  const clearWarningCode = useCallback(() => {
    setWarningCode(undefined);
  }, []);

  const showModal = useCallback((component: React.ElementType, props = {}) => {
    if (isEditing) return;
    setModalState({
      component,
      props,
    });
    disableScroll();
  }, []);

  const hideModal = useCallback(() => {
    setModalState({
      component: null,
      props: {},
    });
    enableScroll();
  }, []);

  const setCart = useCallback((newCart: Cart | undefined) => {
    updateCartState(newCart);
  }, []);

  const fetchCart = useCallback(async () => {
    clearWarningCode();

    cartClient
      .fetchCart()
      .then((res) => {
        setCart(res.data);
        setValue('updatedAt', res.data.updatedAt);
        setValue('site', res.data.site);
        setValue('cartAppearance', res.data.cartAppearance);
        setValue('member', res.data.member);
        if (res.code) handleSetWarningCode(res.code);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  }, []);

  const addItemToCart = useCallback(async (itemId: string) => {
    cartClient
      .addCartItem(itemId)
      .then((res) => {
        setCart(res.data);
        showToast({
          title: t('messages.cart.item_added'),
          type: 'notice',
          icon: Icon.ICONS.CHECK,
        });
      })
      .catch((err) => {
        if (err.response.status === 422) {
          switch (err.response.data.code) {
            case 'not_purchasable':
              showToast({
                title: t('messages.cart.item_not_purchasable'),
                type: 'danger',
                icon: Icon.ICONS.INFO_CIRCLE,
              });
              break;
            case 'multiple_quantities_not_supported':
              showToast({
                title: t('messages.cart.multiple_quantities_not_supported'),
                type: 'notice',
                icon: Icon.ICONS.WARNING,
              });
              break;
            case 'multiple_currencies_not_supported':
              showToast({
                title: t('messages.cart.multiple_currencies_not_supported'),
                type: 'notice',
                icon: Icon.ICONS.WARNING,
              });
              break;
            case 'quantity_limit_reached':
              showToast({
                title: t('messages.cart.item_not_purchasable'),
                type: 'danger',
                icon: Icon.ICONS.INFO_CIRCLE,
              });
              break;
            default:
              showToast({
                title: t('messages.something_went_wrong'),
                type: 'danger',
                icon: Icon.ICONS.WARNING,
              });
          }
        } else {
          Sentry.captureException(err);
          showToast({
            title: t('messages.something_went_wrong'),
            type: 'danger',
            icon: Icon.ICONS.WARNING,
          });
        }
      });
  }, []);

  const updateCartItem = async (cartItemId: string, quantity: number) => {
    cartClient
      .updateCartItem(cartItemId, quantity)
      .then((res) => {
        setCart(res.data);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          showToast({
            title: t('messages.cart.item_not_found_in_cart'),
            type: 'danger',
            icon: Icon.ICONS.INFO_CIRCLE,
          });
        } else if (
          err.response.status === 422 &&
          err.response.data.code === 'quantity_limit_reached'
        ) {
          showToast({
            title: t('messages.offer_quantity_limit_exceeded'),
            type: 'danger',
            icon: Icon.ICONS.INFO_CIRCLE,
          });
        } else {
          Sentry.captureException(err);
          showToast({
            title: t('messages.something_went_wrong'),
            type: 'danger',
            icon: Icon.ICONS.WARNING,
          });
        }
        resetCart();
      });
  };

  const removeItemFromCart = useCallback(async (itemId: string) => {
    cartClient
      .updateCartItem(itemId, 0)
      .then((res) => {
        setCart(res.data);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          showToast({
            title: t('messages.cart.item_not_found_in_cart'),
            type: 'danger',
            icon: Icon.ICONS.INFO_CIRCLE,
          });
        } else {
          Sentry.captureException(err);
          showToast({
            title: t('messages.something_went_wrong'),
            type: 'danger',
            icon: Icon.ICONS.WARNING,
          });
        }
        resetCart();
      });
  }, []);

  const goToCheckout = useCallback(() => {
    setContext({ ...context, step: 1 });
  }, [context]);

  const goBackToCart = useCallback(() => {
    setContext({ ...context, step: 0 });
  }, [context]);

  const submitCheckout = handleSubmit(() => {
    setValue('isPaymentInProgress', true);
    cartClient
      .submitCart(getValues)
      .then((response) => {
        const { id } = response.data;
        getOrderCheckoutStatus(id);
      })
      .catch((err) => {
        setValue('isPaymentInProgress', false);
        if (err.response.status === 409) {
          showToast({
            title: t('messages.cart.stale_cart'),
            type: 'danger',
            icon: Icon.ICONS.WARNING,
          });
          resetCart();
        } else if (
          err.response.status === 422 &&
          err.response.data.code === 'unavailable_items_removed_from_cart'
        ) {
          handleSetWarningCode(err.response.data.code);
          if (err.response.data.data.items.length <= 0) {
            goBackToCart();
          }
          setCart(err.response.data.data);
          setValue('updatedAt', err.response.data.data.updatedAt);
          setValue('site', err.response.data.data.site);
          setValue('cartAppearance', err.response.data.data.cartAppearance);
          setValue('member', err.response.data.data.member);
        } else {
          showToast({
            title: t('messages.something_went_wrong'),
            type: 'danger',
            icon: Icon.ICONS.WARNING,
          });
          resetCart();
        }
        Sentry.captureException(err, { extra: { getValues } });
      });
  });

  const handleCheckoutSucceeded = ({ redirectUrl }: OrderCheckout) => {
    // @ts-ignore
    window.location.replace(redirectUrl);
    setValue('isPaymentInProgress', false);
  };

  const handleRequiresAction = ({ redirectUrl }: OrderCheckout) => {
    // @ts-ignore
    window.location.replace(redirectUrl);
  };

  const handleCheckoutExpired = () => {
    showToast({ title: t('messages.checkout_expired'), type: 'danger', icon: Icon.ICONS.WARNING });
    setValue('isPaymentInProgress', false);
  };

  const handleCheckoutFailed = (orderCheckout: OrderCheckout) => {
    const errorMessage: string = orderCheckout.message || t('messages.something_went_wrong');
    showToast({ title: errorMessage, type: 'danger', icon: Icon.ICONS.WARNING });
    setValue('isPaymentInProgress', false);
  };

  const getOrderCheckoutStatus = useCallback((id) => {
    const pollStatus = () => {
      cartClient
        .fetchOrderCheckoutStatus(id)
        .then((res) => {
          const orderCheckout: OrderCheckout = res.data;
          switch (orderCheckout.status) {
            case 'succeeded':
              handleCheckoutSucceeded(orderCheckout);
              return;
            case 'requires_action':
              handleRequiresAction(orderCheckout);
              return;
            case 'expired':
              handleCheckoutExpired();
              return;
            case 'error':
            case 'failed':
              handleCheckoutFailed(orderCheckout);
              return;
            default:
              setTimeout(pollStatus, 3000);
          }
        })
        .catch((err) => {
          setValue('isPaymentInProgress', false);
          showToast({ title: t('messages.something_went_wrong'), type: 'danger' });
        });
    };

    pollStatus();
  }, []);

  // these values will be available to the useCart hook
  const contextValue = {
    context,
    showModal,
    hideModal,
    fetchCart,
    modal: modalState,
    addItemToCart,
    updateCartItem,
    removeItemFromCart,
    goToCheckout,
    goBackToCart,
    submitCheckout,
    isEditing,
    cartCount,
    warningCode,
  } as CartContextObject;

  return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>;
};

export default CartProvider;
